<script>
import moment from 'moment'
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { relateTypeList } from '@/utils/textFile'
export default {
  name: 'indexBanner',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/market/farmBanner/page',
      current: 1,
      paramsValue: {
        busId: this.$route.query.busId
      }
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          lable: '',
          key: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'imgUrl',
          title: 'Banner',
          align: 'left',
          customRender: (text, records) => {
            const src = records.imgUrl.split(',')[0]
            return (
              <div class="product-order-item-copy">
                <img src={src} />
              </div>
            )
          }
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },

        {
          dataIndex: 'relateType',
          title: '链接类型',
          filters: relateTypeList,
          filterMultiple: false,
          align: 'left',
          onExport: text => (relateTypeList.find(e => e.value == text) || { text: '' }).text,
          customRender: text => (relateTypeList.find(e => e.value == text) || { text: '' }).text
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
          sorter: (a, b) => a.sort - b.sort
        },
        {
          dataIndex: 'endTime',
          title: '下架时间',
          align: 'left',
          sorter: (a, b) => moment(a.endTime).diff(moment(b.endTime))
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          yesText: '上架',
          noText: '下架',
          onExport: records => {
            return ['上架', '下架'][Number(records) - 1]
          },
          filters: [
            {
              text: '上架',
              value: '1'
            },
            {
              text: '下架',
              value: '2'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data === '1' ? '上架' : '下架',
              color: data === '1' ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: records.status === '1' ? '下架' : '上架',
                onClick: () => {
                  api.command.editPost.call(this, {
                    url: `/market/farmBanner/${records.status === '1' ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push({
                    path: '/marketingCenter/indexBannerDetail',
                    query: { id: records.id, busId: this.$route.query.busId }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/market/farmBanner/delete?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push(`/marketingCenter/indexBannerDetail?busId=${this.$route.query.busId}`)
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmBanner/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
